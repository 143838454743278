<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="addUserModal"
      :title="type === 'add' ? `${$t('Add Customer Agent')}`: `${$t('Edit Customer Agent')}`"
      :buttons-hidden="true">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|alpha'" :label-placeholder="$t('First Name')" name="first name" v-model="data.first_name" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('first name')">{{ errors.first('first name') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|alpha'" :label-placeholder="$t('Last Name')" name="last name" v-model="data.last_name" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('last name')">{{ errors.first('last name') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input :disabled="type === 'edit'" v-validate="'required|numeric|max:14'" name="national ID" :label-placeholder="$t('National ID')" v-model="data.national_id" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('national ID')">{{ errors.first('national ID') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="phone nmber" :label-placeholder="$t('Phone Number')" v-model="data.phone_number" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('phone nmber')">{{ errors.first('phone nmber') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|numeric'" name="HR ID" :label-placeholder="$t('HR ID')" v-model="data.hr_id" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('HR ID')">{{ errors.first('HR ID') }}</span>
          </div>
          <div class="vx-col w-1/2 flex mt-10 items-center">
            <span class="mr-3">{{('Use Ringer')}}</span>
            <vs-switch name="use ringer" v-model="data.use_ringer"/>
            <span class="text-danger text-sm" v-show="errors.has('use ringer')">{{ errors.first('use ringer') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <v-select class="mt-7 w-full" name="warehouse" v-on:input="secondary_warehouses ? loadZoneGroups(`${secondary_warehouses.code}`): ''" v-model="secondary_warehouses" :placeholder="$t('Warehouse')" v-validate="'required'" label="name" :options="warehouses"/>
            <span class="text-danger text-sm" v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
          </div>
          <div class="vx-col w-1/2"> 
            <v-select class="mt-7 w-full" name="zone group" v-model="zoneGroup" :placeholder="$t('Zone Group')" label="name" :options="zoneGroups"/>
            <span class="text-danger text-sm" v-show="errors.has('zone group')">{{ errors.first('zone group') }}</span>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button v-if="type === 'add'" @click="addUser" class="active-btn btn">{{ $t('Add') }}</button>
          <button v-else @click="updateUser" class="active-btn btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['warehouses', 'warehousesDic', 'addUserModal', 'type', 'data'],
  data () {
    return {
      apiURL: 'api/v1/users/customer-agents/',
      editMode: true,
      user: {
        first_name: '',
        last_name: '',
        national_id: '',
        phone_number: '',
        primary_warehouse: ''
      },
      secondary_warehouses: [],
      zoneGroups: [],
      zoneGroup: [],
      zoneGroupDic: {}
    }
  },
  watch: {
    type () {
      if (this.type === 'add') {
        this.secondary_warehouses = []
        this.zoneGroup = []
        this.zoneGroups = []
        this.user = Object.assign({}, this.data)
      }
    },
    data () {
      if (this.type === 'edit') {
        this.secondary_warehouses = []
        this.zoneGroup = []
        this.data.primary_warehouse = this.secondary_warehouses.push({
          id: this.data.primary_warehouse,
          name: this.warehousesDic[this.data.primary_warehouse].name,
          code: this.warehousesDic[this.data.primary_warehouse].code
        })
      } else {
        this.secondary_warehouses = []
      }
    },
    'secondary_warehouses' () {
      if (this.type === 'edit' && this.secondary_warehouses) {
        this.loadZoneGroups(this.secondary_warehouses[0] ? this.secondary_warehouses[0].code : this.secondary_warehouses.code)
      }
    }
  },
  methods: {
    loadZoneGroups (warehouseCode) {
      sendRequest(false, false, this, `api/v1/warehouses/${warehouseCode}/zone-groups/`, 'get', null, true,
        (response) => {
          this.zoneGroups = response.data
          this.zoneGroups.forEach(element => {
            this.zoneGroupDic[element.id] = element.name
          })
          if (this.type === 'edit' && this.data.zone_group) {
            this.data.zone_group = this.zoneGroup.push({
              id: this.data.zone_group.id,
              name: this.data.zone_group.name
            })
          }
        }
      )
    },
    updateUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.data.primary_warehouse = this.secondary_warehouses.id
          this.zoneGroup !== null ? this.data.zone_group = this.zoneGroup.id : this.data.zone_group = ''
          this.data.hr_id = Number(this.data.hr_id)
          this.data.use_ringer = this.data.use_ringer
          if (!this.isOnline) this.$emit('addUserModal', false)
          sendRequest(false, false, this, `${this.apiURL}${this.data.uid}/`, 'patch', this.data, true,
            () => {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    addUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.data.zone_group = this.zoneGroup ? this.zoneGroup.id : null
          this.data.primary_warehouse = this.secondary_warehouses.id
          this.data.hr_id = Number(this.data.hr_id)
          this.data.use_ringer = this.data.use_ringer
          if (!this.isOnline) this.$emit('addUserModal', false)
          sendRequest(false, false, this, this.apiURL, 'post', this.data, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('addUserModal', false)
      this.$emit('loadData')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>